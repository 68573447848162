<template>
    <div class="apply-page mb-100">
        <div>
            <img class="w-100" src="../../../assets/img/logo.jpg" alt="">
        </div>
        <div class="container-xxl">
            <div class="page-title d-block text-center">
                <h1>2021太平洋溫泉季</h1>
            </div>
            <div class="discription">
                <div class="col-12 section-title h2">登錄抽獎</div>
                <p class="mb-4 col-12">進行登錄前請詳閱活動內容及注意事項，*為必填欄位。</p>
            </div>
            <div class="card col-12">
                <div class="card-body">
                    <form>
                        <div class="row">
                            <div class="mb-3 row col-xl-12 text-xl-end">
                                <label class="col-xl-3 step-text mb-3">Step 01</label>
                                <div class="col-xl-9">
                                    <div class="row col-xl-12 text-xl-start">
                                        <div class="row col-xl-12 mb-3">
                                            <p><span style="color: red;">*</span>確認旅宿/消費資料</p>
                                        </div>
                                        <div class="row col-xl-12 mb-3 form-group">
                                            <div class="row col-xl-12">
                                                <div class="col-auto">
                                                    <div class="col-auto row">
                                                        <div class="col-auto">
                                                            <div class="form-check">
                                                                <input type="checkbox" :class="{'is-valid': type_validated == 1, 'is-invalid': type_validated == 0}" class="form-check-input mt-2" id="type_1" v-model="apply_data.type_1" @change="onChangeType1($event)">
                                                                <label class="form-check-label" for="type_1">當地住宿</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <div class="form-check">
                                                                <input type="checkbox" :class="{'is-valid': type_validated == 1, 'is-invalid': type_validated == 0}" class="form-check-input mt-2" id="type_2" v-model="apply_data.type_2" @change="onChangeType2($event)">
                                                                <label class="form-check-label" for="type_2">店家消費</label>
                                                            </div>
                                                        </div>
                                                        <div v-if="type_validated === 0" 
                                                            style="
                                                                width: 100%;
                                                                margin-top: 0.25rem;
                                                                font-size: 0.875em;
                                                                color: #dc3545;
                                                            ">
                                                                未選擇當地住宿或店家消費！
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-12 mb-3 form-group" v-if="apply_data.type_1 == true">
                                            <label class="col-form-label col-xl-auto col-lg-auto col-sm-4 col-xs-4 mb-3" for="check_in_date"><span style="color: red;">*</span>請填寫入住日期</label>
                                            <div class="col-xl-auto col-lg-auto col-sm-8 col-xs-8">
                                                <input type="date" :class="{'is-valid': check_in_date_validated == 1, 'is-invalid': check_in_date_validated == 0}" class="form-control" id="check_in_date" v-model="apply_data.check_in_date" required>
                                                <div class="invalid-feedback">入住日期未填寫！</div>
                                            </div>
                                            <label class="col-form-label col-xl-auto col-lg-auto col-sm-4 col-xs-4 mb-3" for="check_out_date"><span style="color: red;">*</span>請填寫退房日期</label>
                                            <div class="col-xl-auto col-lg-auto col-sm-8 col-xs-8">
                                                <input type="date" :class="{'is-valid': check_out_date_validated == 1, 'is-invalid': check_out_date_validated == 0}" class="form-control" id="check_out_date" v-model="apply_data.check_out_date" required>
                                                <div class="invalid-feedback">退房日期未填寫！</div>
                                            </div>
                                        </div>
                                        <div class="row col-12 mb-3 form-group" v-if="apply_data.type_2 == true">
                                            <label class="col-form-label col-xl-auto col-lg-auto col-sm-4 col-xs-4 mb-3" for="paid_date"><span style="color: red;">*</span>請填寫消費日期</label>
                                            <div class="col-xl-auto col-lg-auto col-sm-8 col-xs-8">
                                                <input type="date" :class="{'is-valid': paid_date_validated == 1, 'is-invalid': paid_date_validated == 0}" class="form-control" id="paid_date" v-model="apply_data.paid_date" required>
                                                <div class="invalid-feedback">消費日期未填寫！</div>
                                            </div>
                                        </div>
                                        <div class="row col-xl-12 mb-3">
                                            <label class="col-form-label">請填寫合法民宿、旅館、觀光旅館的名稱</label>
                                        </div>
                                        <div class="row col-xl-12 mb-3">
                                            <div class="col-xl-6">
                                                <input type="text" :class="{'is-valid': accommodation_name_validated == 1, 'is-invalid': accommodation_name_validated == 0}" class="form-control" id="accommodation_name" placeholder="EX：花蓮大飯店" v-model="apply_data.accommodation_name" required>
                                                <div class="invalid-feedback">合法民宿、旅館、觀光旅館的名稱未填寫！</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="mb-3 row col-xl-12 text-xl-end">
                                <label class="col-xl-3 step-text">Step 02</label>
                                <div class="col-xl-9">
                                    <div class="row col-xl-12 text-xl-start">
                                        <div class="row col-xl-12 mb-3">
                                            <p>
                                                發票及收據憑證登錄<br>
                                                以下資訊請依據所持發票或收據憑證資訊做填寫
                                            </p>
                                        </div>
                                        <div class="row col-xl-12 mb-3">
                                            <div class="col-auto">
                                                <div class="form-check">
                                                    <input :class="{'is-valid': approve_validated == 1, 'is-invalid': approve_validated == 0}" type="checkbox" class="form-check-input mt-2" id="approve" required v-model="apply_data.approve">
                                                    <label class="form-check-label" for="approve">如獲獎，同意出示發票或收據正本作為獲獎證明，並查核住宿紀錄</label>
                                                </div>
                                                <div v-if="approve_validated === 0" 
                                                    class="col-xl-12 mb-3"
                                                    style="
                                                        width: 100%;
                                                        margin-top: 0.25rem;
                                                        font-size: 0.875em;
                                                        color: #dc3545;
                                                    ">
                                                    未勾選！
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-xl-12 mb-3">
                                            <label class="col-form-label col-auto"><span style="color: red;">*</span>上傳收據或發票</label>
                                            <div class="col-xl-5">
                                                <input :class="{'is-valid': invoice_image_validated == 1, 'is-invalid': invoice_image_validated == 0}" type="file" ref="invoice_image_input" accept=".png, .jpg, .jpeg" class="form-control" id="invoice_image" @change="invoice_image_change($event.target.files[0])" required>
                                                <div class="invalid-feedback">收據或發票未上傳！</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-3 row col-xl-12 text-xl-end">
                                <label class="col-xl-3 step-text">Step 03</label>
                                <div class="col-xl-9">
                                    <div class="row col-xl-12 text-xl-start">
                                        <div class="row col-xl-12 mb-3">
                                            <p>
                                                以下資訊請依據住宿時，填寫申請人的資料，並提供該次旅遊行程所造的景點做勾選<br>
                                                請填寫申請人資料，並上傳身分證正反面之清晰照片：
                                            </p>
                                        </div>
                                        <div class="row col-xl-12 mb-3 form-group">
                                            <label class="col-form-label col-xl-2 text-xl-end"><span style="color: red;">*</span>姓名</label>
                                            <div class="col-xl-4">
                                                <input :class="{'is-valid': person_name_validated == 1, 'is-invalid': person_name_validated == 0}" type="text" class="form-control" id="person_name" placeholder="EX：王小明" required v-model="apply_data.person_name">
                                                <div class="invalid-feedback">姓名未填寫！</div>
                                            </div>
                                            <label class="col-form-label col-xl-2 text-xl-end"><span style="color: red;">*</span>聯絡電話</label>
                                            <div class="col-xl-4">
                                                <input :class="{'is-valid': phone_validated == 1, 'is-invalid': phone_validated == 0}" type="text" class="form-control" id="phone" placeholder="EX：09123456789" required v-model="apply_data.phone">
                                                <div class="invalid-feedback">聯絡電話未填寫！</div>
                                            </div>
                                        </div>
                                        <div class="row col-xl-12 mb-3">
                                            <label class="col-form-label col-xl-2 text-xl-end"><span style="color: red;">*</span>獎品寄送地址</label>
                                            <div class="col-xl-10">
                                                <input :class="{'is-valid': address_validated == 1, 'is-invalid': address_validated == 0}" type="text" class="form-control" id="address" placeholder="EX：台北市" required v-model="apply_data.address">
                                                <div class="invalid-feedback">獎品寄送地址未填寫！</div>
                                            </div>
                                        </div>
                                        <div class="row col-xl-12 mb-3">
                                            <label class="col-form-label col-xl-2 text-xl-end"><span style="color: red;">*</span>EMAIL信箱</label>
                                            <div class="col-xl-10">
                                                <input :class="{'is-valid': email_validated == 1, 'is-invalid': email_validated == 0}" type="text" class="form-control" id="email" placeholder="EX：AAABBB@gmail.com" required v-model="apply_data.email">
                                                <div class="invalid-feedback">EMAIL信箱未填寫！</div>
                                            </div>
                                        </div>
                                        <div class="row col-xl-12 mb-3">
                                        <label class="col-form-label col-auto"><span style="color: red;">*</span>請上傳身分證正面照片</label>
                                        <div class="col-xl-5">
                                                <input :class="{'is-valid': identity_validated == 1, 'is-invalid': identity_validated == 0}" type="file" ref="identity_input" accept=".png, .jpg, .jpeg" class="form-control" id="identity" @change="identity_change($event.target.files[0])" required>
                                                <div class="invalid-feedback">身分證正面照片未上傳！</div>
                                            </div>
                                        </div>
                                        <div class="row col-xl-12 mb-3">
                                            <label class="col-form-label col-xl-auto"><span style="color: red;">*</span>請填寫住宿/消費人數資訊：</label>
                                            <div class="col-xl-3">
                                                <input :class="{'is-valid': people_validated == 1, 'is-invalid': people_validated == 0}" type="text" class="form-control" id="people" placeholder="EX：6人" required v-model="apply_data.people">
                                                <div class="invalid-feedback">住宿/消費人數資訊未填寫！</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-3 row col-xl-12 text-xl-start">
                                <p>
                                    (1)對於您個人資料的收集，僅限於本次活動抽獎相關程序之使用，一切依循中華民國「個人資料保護法」之規範。於此若您將個人資料送出，即表示您同意主辦單位得於法律許可之範圍內利用相關資料以提供資訊或服務。<br>
                                </p>
                                <p>
                                    (2)參加抽獎活動，請務必填寫以下抽獎資料 (資料填寫不完整者視同放棄)。<br>
                                </p>
                            </div>
                            <div class="row col-xl-12 mb-3">
                                <div class="col-auto">
                                    <div class="form-check">
                                        <input :class="{'is-valid': approve_event_validated == 1, 'is-invalid': approve_event_validated == 0}" type="checkbox" class="form-check-input mt-2" id="approve_event" required v-model="apply_data.approve_event">
                                        <label class="form-check-label" for="approve_event">您已清楚瞭解此活動（2021太平洋溫泉季）同意符合個人資料保護法及相關法規之要求，具有書面同意本站蒐集、處理及使用您的個人資料之效果。</label>
                                    </div>
                                    <div v-if="approve_event_validated === 0" 
                                        class="col-xl-12 mb-3"
                                        style="
                                            width: 100%;
                                            margin-top: 0.25rem;
                                            font-size: 0.875em;
                                            color: #dc3545;
                                        ">
                                        未勾選！
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    &nbsp;
                    <div class="row justify-content-center">
                        <div class="mb-3 row col-xl-12 text-center">
                            <div>
                                <button class="btn btn-apply" @click="submit_data()" :disabled="loading == true">送出</button>
                            </div>
                        </div>
                    </div>
                    &nbsp;
                    <div class="row justify-content-center" v-if="loading == true">
                        <div class="mb-3 row col-xl-12 text-center justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            &nbsp;
                            <div>
                                <span><br>資料送出中...</span>
                            </div>
                        </div>
                    </div>
                    &nbsp;

                    <div style="border-top:1px dashed #000000;height: 1px;overflow:hidden"></div>
                    &nbsp;
                    <div class="row">
                        <div class="mb-3 row col-xl-12 text-start">
                            <p>
                                ［第一重抽獎：住宿消費滿額］<br>
                                <br>
                                活動期間：10/2（六）-10/31（日）<br>
                                <br>
                                截止登錄日期：10/31（日）23:59<br>
                                <br>
                                開獎日期：11/2（二）<br>
                                <br>
                                憑證登錄頁面：<br>
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;活動辦法：<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step1：在花蓮「<strong>瑞穗溫泉區</strong>」和「<strong>安通溫泉區</strong>」鄰近鄉鎮：光復鄉、豐濱鄉、瑞穗鄉、萬榮鄉、富里鄉、卓溪鄉、玉里鎮等七個鄉鎮<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>符合以下條件皆可參加抽獎活動：</strong><br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>1.	單筆消費總額滿NT$500元（含）。</strong><br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>2.	合法旅宿業住宿（可至台灣旅宿網查詢<a href="https://taiwanstay.net.tw" target="_blank" rel="noopener"><strong>https://taiwanstay.net.tw</strong></a>）。</strong><br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step2：提供住宿/消費發票、收據或網路平台收據等憑證，並登錄上傳個人相關資料（此個人資料僅限於2021太平洋溫泉季活動所使用）。<br>
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;注意事項：<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.	單筆消費憑證乙張抽乙次，買越多憑證越多，機會更大。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.	住宿乙晚抽乙次，住的天數越多，中獎機率越大。<br>
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;活動獎項：<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.	iPad Pro(256G)-1台<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.	iPhone12 Pro(256G) -1台<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.	瑞穂天合國際觀光酒店住宿券(1泊2食) -2張<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.	泡湯下午茶套票-10張<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.	瑞穗/安通聯合泡湯券-100張<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.	紅面鴨大禮包-10個<br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sumbit_apply_data } from '../../../api/apply'
import moment from 'moment'

export default {
    name: "apply",
    data() {
        return {
            apply_data: {
                type_1: false,
                type_2: false,
                check_in_date: "",
                check_out_date: "",
                paid_date: "",
                accommodation_name: "",
                approve: "",
                person_name: "",
                phone: "",
                address: "",
                email: "",
                people: "",
                approve_event: false,
            },
            invoice_image: null,
            identity: null,
            // validated
            type_validated: null,
            check_in_date_validated: null,
            check_out_date_validated: null,
            paid_date_validated: null,
            accommodation_name_validated: null,
            approve_validated: null,
            person_name_validated: null,
            phone_validated: null,
            address_validated: null,
            email_validated: null,
            people_validated: null,
            invoice_image_validated: null,
            identity_validated: null,
            approve_event_validated: null,
            // 
            all_validated: false,
            loading: false,
        }
    },
    methods: {
        invoice_image_change(upload_file) {
            this.invoice_image = upload_file;
        },
        identity_change(upload_file) {
            this.identity = upload_file;
        },
        submit_data() {
            this.loading = true;
            this.all_validated = false;
            //
            this.type_validated = null;
            this.check_in_date_validated = null;
            this.check_out_date_validated = null;
            this.paid_date_validated = null;
            this.accommodation_name_validated = null;
            this.approve_validated = null;
            this.person_name_validated = null;
            this.phone_validated = null;
            this.address_validated = null;
            this.email_validated = null;
            this.people_validated = null;
            this.invoice_image_validated = null;
            this.identity_validated = null;
            this.approve_event_validated = null;
            if (this.apply_data.type_1 !== '' && this.apply_data.type_1 !== undefined && 
                this.apply_data.type_2 !== '' && this.apply_data.type_2 !== undefined && 
                (this.apply_data.type_1 === true || this.apply_data.type_2 === true)) {
                this.type_validated = 1
                if (this.apply_data.type_1 === true) {
                    if (this.apply_data.check_in_date !== '' && this.apply_data.check_in_date !== undefined) {
                        this.check_in_date_validated = 1;
                    } else {
                        this.check_in_date_validated = 0;
                    }
                    if (this.apply_data.check_out_date !== '' && this.apply_data.check_out_date !== undefined) {
                        this.check_out_date_validated = 1;
                    } else {
                        this.check_out_date_validated = 0;
                    }
                } else if (this.apply_data.type_2 === true) {
                    if (this.apply_data.paid_date !== '' && this.apply_data.paid_date !== undefined) {
                        this.paid_date_validated = 1;
                    } else {
                        this.paid_date_validated = 0;
                    }
                }
            } else {
                this.type_validated = 0;
            }
            
            if (this.apply_data.accommodation_name !== '' && this.apply_data.accommodation_name !== undefined) {
                this.accommodation_name_validated = 1;
            } else {
                this.accommodation_name_validated = 0;
            }
            if (this.apply_data.approve !== '' && this.apply_data.approve !== undefined) {
                this.approve_validated = 1;
            } else {
                this.approve_validated = 0;
            }
            if (this.apply_data.person_name !== '' && this.apply_data.person_name !== undefined) {
                this.person_name_validated = 1;
            } else {
                this.person_name_validated = 0;
            }
            if (this.apply_data.phone !== '' && this.apply_data.phone !== undefined) {
                this.phone_validated = 1;
            } else {
                this.phone_validated = 0;
            }
            if (this.apply_data.address !== '' && this.apply_data.address !== undefined) {
                this.address_validated = 1;
            } else {
                this.address_validated = 0;
            }
            if (this.apply_data.email !== '' && this.apply_data.email !== undefined) {
                this.email_validated = 1;
            } else {
                this.email_validated = 0;
            }
            if (this.apply_data.people !== '' && this.apply_data.people !== undefined) {
                this.people_validated = 1;
            } else {
                this.people_validated = 0;
            }
            if (this.$refs.invoice_image_input.value !== '' && this.$refs.invoice_image_input.value !== undefined) {
                this.invoice_image_validated = 1;
            } else {
                this.invoice_image_validated = 0;
            }
            if (this.$refs.identity_input.value !== '' && this.$refs.identity_input.value !== undefined) {
                this.identity_validated = 1;
            } else {
                this.identity_validated = 0;
            }
            if (this.apply_data.approve_event !== '' && this.apply_data.approve_event !== undefined && this.apply_data.approve_event === true) {
                this.approve_event_validated = 1;
            } else {
                this.approve_event_validated = 0;
            }

            if (this.type_validated === 1
                && this.accommodation_name_validated === 1
                && this.approve_validated === 1
                && this.person_name_validated === 1
                && this.phone_validated === 1
                && this.address_validated === 1
                && this.email_validated === 1
                && this.people_validated === 1
                && this.identity_validated === 1
                && this.identity_validated === 1
                && this.approve_event_validated === 1
                ) {
                    if (this.apply_data.type_1 === true) {
                        if (this.check_out_date_validated == 1 && this.check_out_date_validated == 1) {
                            this.all_validated = true;
                        } else {
                            this.all_validated = false;
                        }
                    } else if (this.apply_data.type_2 === true) {
                        if (this.paid_date_validated == 1) {
                            this.all_validated = true;
                        } else {
                            this.all_validated = false;
                        }
                    } else {
                        this.all_validated = false;
                    }

            }

            if (this.all_validated === true) {
                if (new Date() > new Date("2021-11-01 00:00:00")) {
                    alert("登錄已截止！");
                    this.loading = false;
                } else if (this.invoice_image === null) {
                    alert("收據或發票檔案未上傳！");
                    this.loading = false;
                } else if (this.identity === null) {
                    alert("身分證正面照片檔案未上傳！");
                    this.loading = false;
                } else if (this.apply_data.check_in_date > this.apply_data.check_out_date) {
                    alert("入住日期不可晚於退房日期！");
                    this.loading = false;
                } else {
                    let form_data = new FormData();
                    form_data.append('apply_data', JSON.stringify(this.apply_data));
                    form_data.append('invoice_image', this.invoice_image, this.invoice_image.name);
                    form_data.append('identity', this.identity, this.identity.name);
                    sumbit_apply_data(form_data).then(response => {
                        if (response.data.success === true) {
                            alert("登錄抽獎資料送出成功！")
                            this.clean_data();
                            this.loading = false;
                        } else {
                            alert("資料送出失敗！\r\n\r\n" + response.data.message)
                            this.loading = false;
                        }
                    }, error => {
                            // console.log(error)
                        this.loading = false;
                    });
                }
            } else {
                this.loading = false;
            }
        },
        clean_data() {
            this.apply_data = {
                type_1: false,
                type_2: false,
                check_in_date: "",
                check_out_date: "",
                paid_date: "",
                accommodation_name: "",
                approve: "",
                person_name: "",
                phone: "",
                address: "",
                email: "",
                people: "",
                approve_event: false,
            }
            this.invoice_image = null;
            this.identity = null;
            this.$refs.invoice_image_input.value = null;
            this.$refs.identity_input.value = null;
            // validated
            this.type_validated = null;
            this.check_in_date_validated = null;
            this.check_out_date_validated = null;
            this.paid_date_validated = null;
            this.accommodation_name_validated = null;
            this.approve_validated = null;
            this.person_name_validated = null;
            this.phone_validated = null;
            this.address_validated = null;
            this.email_validated = null;
            this.people_validated = null;
            this.invoice_image_validated = null;
            this.identity_validated = null;
            this.approve_event_validated = null;
            //
            this.all_validated = false;
        },
        onChangeType1(event) {
            if (this.apply_data.type_1 == true) {
                this.apply_data.type_2 = false;
            }
        },
        onChangeType2(event) {
            if (this.apply_data.type_2 == true) {
                this.apply_data.type_1 = false;
            }
        },
        datetime_format(datetime) {
            return moment(datetime).format('YYYY/MM/DD HH:mm:ss')
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
    }
}
</script>

<style lang="scss" scoped>
.btn-apply {
    background-color: #9F9FA0;
    border-radius: 25px;
    width: 150px;
    color: #FFFFFF;
}
.apply-page {
    overflow: auto;
    position: relative;
}
.container-xxl{
    &:first-child{
        margin-top: 70px;
    }
}
@media  (max-width: 991.98px) and (min-width:768px){
    .container-xxl{
        &:first-child{
            margin-top: 135px;
        }
    }
}
@media  (max-width: 767.98px) and (min-width:576px){
    .container-xxl{
        &:first-child{
            margin-top: 110px;
        }
    }
}
@media  (max-width: 575.98px){
    .container-xxl{
        &:first-child{
            margin-top: 80px;
        }
    }
}
.notice {
    display: inline-block;
    margin-right: 12px;
    background-color: #00AEC4;
    border-radius: 50px;
    color:#fff;
    padding: 6px 44px;
}
.step-text {
    font-size: 1.25rem;
    color: #00AEC4;
    font-weight:bold;
}
</style>