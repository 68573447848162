<template>
    <footer class="footer mt-auto">
        <div class="container-xxl font18">
            <div class="row web-infomation">
                <div class="infomation col-2 text-center mt-4">
                    <a>
                        <img src="../assets/img/footer/footer-logo.png" class="footer-logo-png" alt="">
                    </a>
                </div>
                <div class="footer-about col-10">
                    <div class="row">
                        <ul class="connection col-md-4">
                            <li>諮詢專線：</li>
                            <li class="font16">
                                <a href="tel:+886-3-8227171">
                                    (03)8227171
                                </a>
                            </li>
                            <li class="font16">週一至週五 上午08:00-12:00 下午1:30-5:30</li>
                        </ul>
                        <ul class="connection col-md-4">
                            <li>位置資訊：</li>
                            <li class="font16">
                                <a href="https://goo.gl/maps/XPNtMY5i6SinWMC38" target="_blank">
                                    <i class="bi bi-geo-alt-fill"></i>花蓮縣花蓮市府前路17號
                                </a>
                            </li>
                        </ul>
                        <ul class="connection col-md-4">
                            <li>客服信箱：</li>
                            <li class="font16">
                                <a href="https://goo.gl/maps/XPNtMY5i6SinWMC38" target="_blank">
                                    service@wowmedia.com.tw
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p></p>
                    <div class="row">
                        <div class="version col-12 pt-3">
                            <div class="box d-flex justify-content-between border-top pt-3">
                                <p class="font16">請使用IE第10版以上、Chrome、FireFox、Edge、Safari瀏覽器瀏覽</p>
                                <!-- <p class="font16">本網站為花蓮縣政府版權所有</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scope>
.footer{
    background-color: #045198;
    color:#fff;
    padding-top: 32px;
    padding-bottom: 32px;
    a{
        color:#fff;
    }
}
.container-xxl{
    margin-top: 44px;
    .web-infomation{
        position: relative;
        .infomation{
            margin-bottom: 32px;
        }
        .footer-about{
            margin-bottom: 30px;
            .connection, .loaction{
                li{
                    &:first-child{
                        margin-bottom: 24px;
                    }
                }
            }
        }
        .social-media{
            position: absolute;
            right:0;
            top:0;
            width: auto;
        }
    }
}
@media  (max-width: 1199.98px) and (min-width: 768px){
    .web-infomation{
        position: relative;
        .accessibility{
            position: absolute;
            right:0;
            top:0;
        }
    }
}
@media  (max-width: 991.98px){
    .version{
        .box{
            display: block !important;
        }
    }
}
@media  (max-width: 767.98px){
    .container-xxl{
        .web-infomation{
            .infomation{
                a{
                    display: block;
                    margin: 0 !important;
                }
            }
            .footer-about{
                .connection{
                    margin-bottom: 30px;
                }
            }
        }
    }
}
@media  (max-width: 575.98px){
    .container-xxl{
        .web-infomation{
            .social-media{
                position: static;
                display: block;
                margin-bottom: 30px;
            }
        }
    }
}

.footer-logo-png { 
    width: 100%; 
    max-width: 115px; 
} 
</style>