<template>
  <div>
    <router-view />
    <Footer/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Footer from "../components/Footer.vue";
import Apply from "../views/pages/apply/Apply.vue";

@Options({
  components: {
    Footer,
    Apply,
  },
})
export default class Home extends Vue {}
</script>

<style lang="scss">
</style>