import request from "../utils/request";

export function sumbit_apply_data(data: FormData) {
    return request({
        url: "/api/apply_data",
        method: "post",
        data: data,
        withCredentials: false,
    });
}
